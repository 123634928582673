import useOutlet from "./useOutlet";
import motorbike from "../../../common/images/revamp/icon/motorbike.svg";
import tagPrice from "../../../common/images/revamp/icon/tag-purple.svg";
import star from "../../../common/images/revamp/icon/star.svg";
import { ChevronRightIcon, ClockIcon } from "@heroicons/react/24/solid";
import OutletByLocation from "./Location";
import {
  MathRandom,
  sendAnalyticsCustomEvent,
  sendAnalyticsEvent,
  checkTime,
} from "../../../utils/helper";
import { useHistory } from "react-router-dom";
import NoOutlet from "./NoOutlet";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { LoaderIcon } from "../../../utils/svg";
import ImageLoader from "../../ImageLoader";
import { useEffect } from "react";

const Outlet = () => {
  const history = useHistory();
  const {
    outlets,
    loading,
    outletByLocation,
    isOpenOtherLocation,
    setIsOpenOtherLocation,
    handleOutletByLocation,
    isLoadMore,
    isLoadingOtherLocation,
    selectedStoreID,
  } = useOutlet();

  const selectedTag = useSelector((store) => store?.tag?.selected);

  const filteredOutlet = useMemo(() => {
    if (!selectedTag) return outlets;
    const result = outlets?.filter((outlet) => {
      const tagArray = outlet?.tagID ? outlet.tagID.split(",") : [];
      return tagArray.includes(String(selectedTag));
    });
    return result;
  }, [selectedTag, outlets]);

  const handleClickOutlet = (item) => {
    if (item?.storeOpen === "1") {
      history.push({
        pathname: `/outlet/${item?.storeSlug}`,
        state: {
          storeID: item?.storeID,
          storeSlug: item?.storeSlug,
          storeName: item?.storeName,
          storeImage: item?.storeImage,
          rating: item?.rating,
        },
      });
    }
  };

  useEffect(() => {
    sendAnalyticsCustomEvent("view_merchants_list", {
      merchants_list_id: "related_products",
      merchants_list_name: "Related products",
      items: outlets,
    });
  }, []);

  return (
    <div className="w-full px-4 mb-10 space-y-[26px] max-w-lg">
      {loading &&
        new Array(6).fill("").map((item, index) => (
          <div key={index} className="flex justify-between gap-3">
            <div className="rounded-lg bg-grayD3 animate-pulse w-[95px] h-[95px]"></div>
            <div className="grow space-y-1">
              <div
                className="rounded-sm bg-grayD3 animate-pulse h-[21px] max-w-full"
                style={{ width: `${MathRandom(80, 200)}px` }}
              ></div>
              <div className="rounded-sm bg-grayD3 animate-pulse h-[21px] w-16 max-w-full"></div>
              <div
                className="rounded-sm bg-grayD3 animate-pulse h-[21px] max-w-full"
                style={{ width: `${MathRandom(60, 90)}px` }}
              ></div>
              <div className="rounded-sm bg-grayD3 animate-pulse h-[21px] w-36 max-w-full"></div>
            </div>
          </div>
        ))}

      {filteredOutlet?.map((item, index) => {
        const distance = parseFloat(item?.distance || 0)?.toFixed("2");
        return (
          <div key={item?.id || index} className="flex justify-between gap-3">
            <div
              className="aspect-[1/1] h-[95px] w-[95px] cursor-pointer"
              onClick={() => {
                sendAnalyticsEvent(
                  "Click Outlet",
                  "homepage_select_outlet",
                  item?.storeName
                );
                handleClickOutlet(item);
              }}
              id="homepage_select_outlet"
            >
              <ImageLoader src={item?.storeImage} alt={item?.storeImage} />
            </div>
            <div className="grow">
              <div
                onClick={() => handleClickOutlet(item)}
                className="grow space-y-1"
              >
                <div
                  className={`${
                    item?.storeOpen === "0" ? "flex" : "hidden"
                  } items-center`}
                >
                  <ClockIcon class="h-4 w-4 text-red-500 mr-1" />
                  <div className="text-xs font-normal text-gray-900">
                    Closed{" "}
                    {item?.outletOpenTime &&
                      `- Open at ${item?.outletOpenTime} ${checkTime(
                        item?.outletOpenTime
                      )} Today`}
                  </div>
                </div>
                <p
                  className={`mb-0 font-semibold cursor-pointer ${
                    item?.storeOpen === "0" ? "text-gray-400" : "text-black37"
                  } line-clamp-1 max-w-[calc(100vw-139px)]`}
                >
                  {item?.storeName}
                </p>
                {item?.rating && (
                  <div className="flex items-center gap-1">
                    <img src={star} alt="star" />
                    <span className="font-medium text-sm text-black37">
                      {item?.rating || 0} ({item?.totalRating || 0})
                    </span>
                  </div>
                )}
                <div className="flex items-center gap-1">
                  <img src={motorbike} alt="motorbike" />
                  <span>{distance || 0} km</span>
                </div>
                {item?.storeTimeInfo && (
                  <div className="flex items-center gap-1">
                    <img src={tagPrice} alt="tagPrice" />
                    <span className="font-medium text-sm text-black37">
                      {item?.storeTimeInfo}
                    </span>
                  </div>
                )}
                {item?.offerInfo && (
                  <div className="flex items-center gap-1">
                    <img src={tagPrice} alt="tagPrice" />
                    <span className="font-medium text-sm text-black37">
                      {item?.offerInfo}
                    </span>
                  </div>
                )}
              </div>
              <div
                id="homepage_see_other_outlets"
                className="inline-flex gap-2 items-center py-1.5 px-2.5 bg-ufdPurple/10 text-ufdPurple rounded-full mt-0.5 cursor-pointer min-w-40 justify-between"
                onClick={() => handleOutletByLocation(item)}
              >
                <div className="w-full flex justify-center">
                  {isLoadingOtherLocation &&
                  selectedStoreID === item?.storeID ? (
                    <LoaderIcon />
                  ) : (
                    "See other location"
                  )}
                </div>
                <ChevronRightIcon className="text-ufdPurple w-5 h-5" />
              </div>
            </div>
          </div>
        );
      })}
      {!isLoadingOtherLocation && outletByLocation?.length ? (
        <OutletByLocation
          isOpen={isOpenOtherLocation}
          outlet={outletByLocation}
          onClose={setIsOpenOtherLocation}
        />
      ) : !isLoadingOtherLocation ? (
        <NoOutlet
          isOpen={isOpenOtherLocation}
          onClose={() => setIsOpenOtherLocation(false)}
        />
      ) : null}
      {isLoadMore && (
        <div className="flex justify-center">
          <LoaderIcon />
        </div>
      )}
    </div>
  );
};

export default Outlet;
