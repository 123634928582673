import React, { useEffect } from 'react';
import search from '../../common/images/revamp/icon/search.svg';
import Tags from './Tags';
import Banner from './Banner';
import Order from './Order';
import Outlet from './Outlet';
import Header from './Header';
import { Link } from 'react-router-dom';
import { isEmptyData, sendAnalyticsCustomEvent, sendAnalyticsPageView } from '../../utils/helper';
import { uniqueID } from '../../utils/constants';
import qs from 'qs';
import { getDetailLocationFromLatLong } from '../../utils/location';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../store/reducers/user';
import Category from './Category';
import { axiosInstance } from '../../utils/helper';

if (process.env.REACT_APP_NODE_ENV === 'production') {
	console.log = function no_console() {};
} else {
	const originalConsoleError = console.error;
	console.error = function (message) {
		if (!message.includes('Warning: findDOMNode is deprecated')) {
			originalConsoleError.apply(console, arguments);
		}
	};
}

const NewHome = () => {
	const dispatch = useDispatch();

	const queryParams = new URLSearchParams(window.location.search);

	const token = queryParams.get('token');
	const refresh_token = queryParams.get('refresh_token');
	const latitude = queryParams.get('latitude');
	const longitude = queryParams.get('longitude');

	const user = useSelector((store) => store?.user);

	const handleLogin = async (token) => {
		console.log('handleLogin');
		try {
			const data = {
				unquieid: uniqueID,
				token: token,
			};

			const res = await axiosInstance.post(`customers/login`, qs.stringify(data));
			if (res.data.status === 'ok') {
				const { firstName, lastName, email, primary_phone, customerUVID, customerID, companyID, companyName } = res.data.result;

				const userData = {
					firstName,
					lastName,
					email,
					primary_phone,
					customerUVID,
					customerID,
          companyID,
          companyName,
				};
        sendAnalyticsCustomEvent('login', { company_id: companyID, company_name: companyName});
				dispatch(setUser(userData));
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleGetAddressFromLatLon = async (latitude, longitude) => {
		console.log('handleGetAddressFromLatLon');
		try {
			const addressData = await getDetailLocationFromLatLong(latitude, longitude);
			console.log('addressData: ', addressData);
			if (addressData) {
				dispatch(
					setUser({
						address: addressData?.address,
						latitude: addressData?.latitude,
						longitude: addressData?.longitude,
						country: addressData?.country,
						state: addressData?.state,
						city: addressData?.city,
						village: addressData?.village,
						district: addressData?.district,
						postalCode: addressData?.postalCode,
					}),
				);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	useEffect(() => {
		if (isEmptyData(token) || isEmptyData(refresh_token)) return;
		dispatch(
			setUser({
				...user,
				token: token,
				refresh_token: refresh_token,
			}),
		);
		handleLogin(token);
	}, [token, refresh_token]);

	useEffect(() => {
		if (isEmptyData(latitude) || isEmptyData(longitude)) return;
		handleGetAddressFromLatLon(latitude, longitude);
	}, [latitude, longitude]);

  useEffect(() => {
    sendAnalyticsPageView("homepage_session");
  }, []);

  useEffect(() => {
    if (user?.address) {
      sendAnalyticsCustomEvent('homepage', { address: user?.address}); 
    }
  }, [user?.address])
  

	return (
		<div className='flex flex-col items-center'>
			{/* <Loader overlay /> */}
			<div className='gradient w-full h-[110px] flex items-center px-4 text-white mb-10 sticky top-0 z-5'>
				<Header />

				<Link
          id="homepage_search"
					className='flex gap-2.5 items-center absolute bottom-[-22px] w-[calc(100vw-32px)] h-11 rounded-full p-2.5 bg-white border border-solid border-[#D3D3D3] shadow-[0px_0px_22px_0px_#0000001A]'
					to='/search'>
					<img src={search} alt='search' className='w-6 h-6' />
					<span className='text-[#909090] text-sm'>what food are you looking for</span>
				</Link>
			</div>

			<Order />

			{/* <Category /> */}

			<Banner />

			<Tags />

			<Outlet />
		</div>
	);
};

export default NewHome;
